import PropTypes from 'prop-types';
import React from 'react';
import api from '../../../requests';
import { toast } from 'react-toastify';
import Highcharts from 'highcharts/highstock';
import PieChart from "highcharts-react-official";

const UserStat = ({ className, ...rest }) => {
    const [stat, setStat] = React.useState([]);
    const options = {
        chart: {
            type: 'pie'
          },
        title: {
          text: 'utilisateurs'
        },
        series: [
          {
            data: stat
          }
        ]
      };

    const getStat = async () => {
        let path = '/admin/stat/user';
        try {
            const response = await api.get(path);

            if (response.status === 200) {
                let rows = [];
                for (const row of response.data) {

                    let rowObject = {
                        y: Number(row.count),
                        name: row.name
                    }
                    rows.push(rowObject);
                }
                setStat(rows);
            } else {
                toast.error(response.data.message);
            }
        } catch (err) {
            toast.error(err.response.data.message);
        }
    }

    React.useEffect(() => {
        getStat();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <div>
            <PieChart
                highcharts={Highcharts}
                options={options}
            />
        </div>
    );
}

UserStat.propTypes = {
    className: PropTypes.string,
};
UserStat.defaultProps = {
    className: '',
};

export default React.memo(UserStat);
